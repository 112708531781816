import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import { UnlockProps } from './interfaces';
import {
	CheckmarkBig,
	Close,
	Copy,
	Enter,
	Off,
	Setup,
} from '../../assets/Icons';
import AppStateContext from '../../AppStateProvider/context';
import PasswordInput from '../Input/passwordInput';
import Keyboard from '../Keyboard';
import addParam from '../../utils/addParam';
import { setLanguage } from '../../utils/setLanguage';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../RadioButtons';
import { checkPW } from '../../utils/checkPW';
import * as packageJson from '../../../package.json';

type Option = {
	selected?: boolean;
	text: string;
	value: any;
	displayNone?: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
};
export default function Unlock(props: UnlockProps): JSX.Element {
	const { state } = useContext(AppStateContext);
	const { i18n, t } = useTranslation();
	const [pw, setPW] = useState<string | false>('');
	const [hidden, setHidden] = useState<boolean>(true);
	const [close, setClose] = useState<boolean>(props.close || false);
	const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
	const [copied, setCopied] = useState(false);
	const chromebox = new URLSearchParams(window.location.search).get(
		'chromebox'
	);
	const setupVersion = packageJson.version;
	const brandVersion = localStorage.getItem('version');
	const apkVersion = new URLSearchParams(window.location.search).get(
		'version'
	);

	const versionsString = `WebDID version: ${brandVersion}\nSetup version: ${setupVersion}\n${
		apkVersion ? 'APK version: ' + apkVersion : ''
	}\n`;

	const options: Option[] = [
		{
			selected: !close,
			text: t('UnlockOpenSetup'),
			value: false,
			icon: Setup,
			disabled: !isOnline,
		},
	];

	if (!chromebox) {
		options.push({
			selected: close,
			text: t('UnlockTurnOff'),
			value: true,
			icon: Off,
		});
	}

	const copyVersionsToClipboard = () => {
		navigator.clipboard
			.writeText(versionsString)
			.then(() => {
				setCopied(true);
				setTimeout(() => {
					setCopied(false);
				}, 2000);
			})
			.catch(err => console.error('Failed to copy versions: ', err));
	};

	window.addEventListener('online', () => setIsOnline(true));
	window.addEventListener('offline', () => setIsOnline(false));

	const onEnter = () => {
		if (pw && checkPW(pw, state)) {
			if (close) {
				addParam('locked', false, true);
				window.parent.postMessage('locked', '*');
			} else if (props.inFrame) {
				window.parent.postMessage('redirect', '*');
			}
		} else {
			setPW(false);
		}
	};

	const onClose = () => {
		if (props.inFrame) {
			setHidden(true);
			setTimeout(() => {
				window.parent.postMessage('close', '*');
			}, 300);
		} else if (props.cancel) {
			props.cancel();
		}
	};

	const renderDate = Date.now();

	useEffect(() => {
		if (props.inFrame) {
			setLanguage(
				state.language,
				i18n,
				() => setHidden(false),
				() => setHidden(false)
			);
		} else if (hidden) {
			setHidden(false);
		}
	}, []);

	return (
		<div
			className={`unlock${hidden ? ' hidden' : ' not-hidden'}${
				props.inFrame ? ' in-frame' : ' no-frame'
			}${state.apk ? ' apk' : ''}`}
			onClick={e => {
				if (
					e.target === e.currentTarget &&
					Date.now() - renderDate > 3000
				)
					onClose();
			}}
		>
			{props.inFrame && (
				<>
					<img
						src='./DID_SetupApp_Placeholder.png'
						alt='animation placeholder'
					/>
					<div className='versions'>
						<div className='copy' onClick={copyVersionsToClipboard}>
							{copied ? CheckmarkBig : Copy}
						</div>
						<div onClick={copyVersionsToClipboard}>
							{copied ? (
								<p>Copied to clipboard</p>
							) : (
								<>
									<p>WebDID version: {brandVersion}</p>
									<br />
									<p>Setup version: {setupVersion}</p>
									<br />
									{apkVersion && (
										<p>APK version: {apkVersion}</p>
									)}
								</>
							)}
						</div>
					</div>

					{state.apk && (
						<>
							<RadioButtons
								warning={
									!isOnline
										? t('UnlockOfflineWarning')
										: undefined
								}
								options={options}
								onClick={(v: boolean) => setClose(v)}
							/>
							<div className='continue'>
								<button className='black' onClick={onClose}>
									{Close}
									{t('Cancel')}
								</button>
								<button className='white' onClick={onEnter}>
									{Enter}
									{t('ConfirmInput')}
								</button>
							</div>
						</>
					)}
				</>
			)}
			<div className='unlock-keyboard'>
				<div className='input-container'>
					{typeof props.cancel === 'function' &&
						!(props.inFrame && state.apk) && (
							<button className='black' onClick={onClose}>
								{Close}
								{t('Cancel')}
							</button>
						)}
					<PasswordInput
						className={pw === false ? 'wrong' : ''}
						value={pw || ''}
						autoFocus={true}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								onEnter();
							}
						}}
						onInput={e =>
							setPW((e.target as HTMLInputElement).value)
						}
						errorMessage={pw === false ? '' : undefined}
					/>
				</div>
				<Keyboard onEnter={onEnter} noMovement={true} />
			</div>
		</div>
	);
}
